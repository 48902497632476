import React from "react"

const BasePage = () => {
	console.log( "base" )
	return (
		<div>
		</div>
	)
}


export default BasePage
